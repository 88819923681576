import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/SEO"
import "../scss/main.scss"
import { DiscussionEmbed } from 'disqus-react'

export default function Template({ data }) {
  const post = data.markdownRemark
  const slug = post.fields.slug
  const titles = post.frontmatter.title
    const disqusConfig = {
    shortname: 'bbsbb',
    config: { identifier: slug, titles,
              title: titles },
    }

  return (
    <Layout>
      <Seo title={post.frontmatter.title} description={post.frontmatter.description} />
      <div className="blog-post">
        <h1>{post.frontmatter.title}</h1>
        <h6>{post.frontmatter.date}</h6>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <div>
        <DiscussionEmbed {...disqusConfig} />
        </div>
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
      }
      fields {
        slug
      }
    }
  }
`

